import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import config from "../src/components/config.json";
import { useEffect, useRef } from "react";

const initialState = {
  emailRef: "",
  nameRef: "",
  message1: ""
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const message1 = useRef(null);


  useEffect(() => emailjs.init(config.publicId), []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(name, email, message);
    const serviceId = config.serviceId;
    const templateId = config.templateId;
    const publicId = config.publicId;
    // console.log(serviceId,templateId,publicId);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
  try {
    await emailjs.send(serviceId, templateId, {
        to_name: 'Admin',
        from_name: nameRef.current?.value,
        message: message1.current?.value,
        recipient: emailRef.current?.value,
        from_email: emailRef.current?.value
    });
    
    alert("email successfully sent check inbox");
} 
catch (error) {
    console.log(error);
} 
finally {
  nameRef.current.value=""
  message1.current.value=""
  emailRef.current.value=""
}
};
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control" ref={nameRef}
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control" ref={emailRef}
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control" ref={message1}
                    rows="4"
                    placeholder="Message" 
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.companyname : "loading"}
                <br></br>
                {props.data ? props.data.address : "loading"}
              </p>
              </div>
              <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
              <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Branch
                </span>
                {props.data ? props.data.africacompany : "loading"}
                <br></br>
                {props.data ? props.data.africaaddress : "loading"} 
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.africaphone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy;  2024, KathiriyaLaw. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
