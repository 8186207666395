import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          Our solutions are based on the objectives of our clients; and not based on our structures.
          </p>
          <br></br>
          <p>
          We have got wide range of services where we synergize with our client
to achieve their desired growth and profitability. Our solutions cover
specific professional needs regardless of complexity and geographic location.
We have dynamic and energetic people who are constantly engaged in
providing innovative solutions to the following set of clients
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
